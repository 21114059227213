import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { loadCurrentUser } from "./containers/Auth/auth.slice";
import { Spin } from "antd";
import { axios } from "./util/axios";

import AppRoutes from "./app.routes";

import "./styles/globals.css";

function App() {
  // Load current user

  const dispatch = useDispatch();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const user = await axios.get("/users/me");
      const staff = await axios.get(`/organization-staffs/${user.data.staff}`);
      const staffData = staff.data;
      staffData.job_role = staffData.job_role ? staffData.job_role.id : null;
      staffData.manager = staffData.manager ? staffData.manager.id : null;
      staffData.organization_department = staffData.organization_department
        ? staffData.organization_department.id
        : null;
      staffData.user = staffData.user ? staffData.user.id : null;
      const authJson = {
        ...user.data,
        staff: staffData,
      };
      dispatch(loadCurrentUser(authJson));
      setIsUserLoaded(true);
    } catch (e) {
      localStorage.removeItem("auth");
      setIsUserLoaded(true);
    }
  };

  return (
    <>
      {isUserLoaded ? (
        <AppRoutes />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      )}
    </>
  );
}

export default App;
