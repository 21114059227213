import { makeUseAxios } from "axios-hooks";
import { BASE_URL } from "./server";
import * as rax from "retry-axios";

const { default: Axios } = require("axios");

export const axios = Axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const authedAxios = Axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  raxConfig: {
    statusCodesToRetry: [
      [100, 199],
      [429, 429],
      [401, 403],
      [500, 599],
    ],
  },
});

authedAxios.defaults.raxConfig = {
  instance: authedAxios,
};

rax.attach(authedAxios); // To Retry if Error Occurs

authedAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    throw error;
  }
);

export const useAuthedAxios = makeUseAxios({
  axios: authedAxios,
});

export const useAxios = makeUseAxios({
  axios: axios,
});
