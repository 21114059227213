import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../containers/Auth/auth.slice";
import developReducer from "../containers/User/Develop/develop.slice";
import employeeReducer from "../containers/User/employee.slice";

export default configureStore({
  reducer: {
    auth: authReducer,
    develop: developReducer,
    employee: employeeReducer,
  },
});
