export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";
export const ERROR = "ERROR";

export const ADDED = "ADDED";

export const ALLOWED_ROLES = ["organization_l_d_manager", "user"];

export const MOBILE_BREAKPOINT_WIDTH = 480;

export const ASSESS_SKILLS = "ASSESS_SKILLS";
export const EXPLORE_OPPORTUNITIES = "EXPLORE_OPPORTUNITIES";
export const PLAN_AND_COMMIT = "PLAN_AND_COMMIT";

export const SELF_COLOR = "#f7c033";
export const PEER_COLOR = "#1e70c0";
export const MANAGER_COLOR = "#8661b1";
export const STAKEHOLDER_COLOR = "#3badad";
