import { Spin } from "antd";
import React from "react";

function CenterSpinner(props) {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin {...props} />
    </div>
  );
}

export default CenterSpinner;
