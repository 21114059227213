import { Spin } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { axios } from "./axios";

export function ProtectedRoute({ children, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    try {
      await axios.get("/users/me");
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  };

  if (isAuthenticated === null) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
