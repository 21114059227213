import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { BASE_URL } from "../util/server";
import { onError } from "@apollo/client/link/error";
import { axios } from "../util/axios";

const httpLink = createHttpLink({
  uri: `${BASE_URL}/graphql`,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const isAuthError = graphQLErrors.find(
      (r) => r.message === "Invalid token."
    );

    if (isAuthError) {
      axios.post("/logout").finally(() => window.location.reload());
    }
  }

  if (networkError) {
    // Handling Network Error
  }
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});
